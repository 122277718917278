import React from "react"

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import { Link } from "gatsby"

import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "swiper/components/scrollbar/scrollbar.scss"
import "swiper/components/effect-fade/effect-fade.scss"

// Import Swiper styles
import "swiper/swiper.scss"

import slider1 from "@images/slider-img1.jpg"
import slider2 from "@images/slider-img2.jpg"

const slide1st = {
  background: `linear-gradient(0deg, rgba(40, 89, 67, 0.6), rgba(40, 89, 67, 0.6)),url(${slider1})`,
  height: "100vh",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
}
const slide2nd = {
  background: `linear-gradient(0deg, rgba(40, 89, 67, 0.6), rgba(40, 89, 67, 0.6)),url(${slider2})`,
  height: "100vh",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
}

// const slide2nd = {
//   background: `url(${slider2})`,
//   padding: "130px 0 250px",
//   marginTop: "4em",
//   backgroundSize: "cover",
//   backgroundRepeat: "no-repeat",
//   backgroundPosition: "top center",
// }

// const slide3rd = {
//   background: `url(${slider3})`,
//   padding: "130px 0 250px",
//   marginTop: "4em",
//   backgroundSize: "cover",
//   backgroundRepeat: "no-repeat",
//   backgroundPosition: "top center",
// }

// const slide4th = {
//   background: `url(${slider4})`,
//   padding: "130px 0 250px",
//   marginTop: "4em",
//   backgroundSize: "cover",
//   backgroundRepeat: "no-repeat",
//   backgroundPosition: "top center",
// }

// const slide5th = {
//   background: `url(${slider5})`,
//   padding: "130px 0 250px",
//   marginTop: "4em",
//   backgroundSize: "cover",
//   backgroundRepeat: "no-repeat",
//   backgroundPosition: "top center",
// }

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade])

const SwiperTest = () => {
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
    >
      <SwiperSlide>
        <div className="hero-slider">
          <div className="slider" style={slide1st}>
            <div className="container">
              {" "}
              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex justify-content-center align-items-center">
                    {/* <div>
                      <h1 className="heading-yellow">Meet The EMMA X3</h1>
                      <p style={{ maxWidth: "75%" }}>
                        The Emma X3 all terrain wheelchair is light,
                        comfortable, compact, it glides over sand, rocks, grass,
                        gravel and it even floats in water. Don’t believe us?
                      </p>
                      <p style={{ maxWidth: "65%" }}>
                        Discover what the Emma X3 All Terrain Wheelchair is
                        capable of.
                      </p>
                      <Link className="btn mt-4" to="/the-emma-x3/">
                        Learn More
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="hero-slider">
          <div className="slider" style={slide2nd}>
            <div className="container">
              {" "}
              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex justify-content-center align-items-center">
                    {/* <div>
                      <h1 className="heading-yellow">Meet The EMMA X3</h1>
                      <p style={{ maxWidth: "75%" }}>
                        The Emma X3 all terrain wheelchair is light,
                        comfortable, compact, it glides over sand, rocks, grass,
                        gravel and it even floats in water. Don’t believe us?
                      </p>
                      <p style={{ maxWidth: "65%" }}>
                        Discover what the Emma X3 All Terrain Wheelchair is
                        capable of.
                      </p>
                      <Link className="btn mt-4" to="/the-emma-x3/">
                        Learn More
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      {/* <SwiperSlide>
        <div className="hero-slider">
          <div className="slide2" style={slide2nd}>
            <div className="container">
              {" "}
              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      <h1 className="heading-yellow">Meet The EMMA X3</h1>
                      <p style={{ maxWidth: "75%" }}>
                        The Emma X3 all terrain wheelchair is light,
                        comfortable, compact, it glides over sand, rocks, grass,
                        gravel and it even floats in water. Don’t believe us?
                      </p>
                      <p style={{ maxWidth: "65%" }}>
                        Discover what the Emma X3 All Terrain Wheelchair is
                        capable of.
                      </p>
                      <Link className="btn mt-4" to="/the-emma-x3">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="hero-slider">
          <div className="slide3" style={slide3rd}>
            <div className="container">
              {" "}
              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      <h1 className="heading-yellow">Meet The EMMA X3</h1>
                      <p style={{ maxWidth: "75%" }}>
                        The Emma X3 all terrain wheelchair is light,
                        comfortable, compact, it glides over sand, rocks, grass,
                        gravel and it even floats in water. Don’t believe us?
                      </p>
                      <p style={{ maxWidth: "65%" }}>
                        Discover what the Emma X3 All Terrain Wheelchair is
                        capable of.
                      </p>
                      <Link className="btn mt-4" to="/the-emma-x3/">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="hero-slider">
          <div className="slide4" style={slide4th}>
            <div className="container">
              {" "}
              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      <h1 className="heading-yellow">Meet The EMMA X3</h1>
                      <p style={{ maxWidth: "75%" }}>
                        The Emma X3 all terrain wheelchair is light,
                        comfortable, compact, it glides over sand, rocks, grass,
                        gravel and it even floats in water. Don’t believe us?
                      </p>
                      <p style={{ maxWidth: "65%" }}>
                        Discover what the Emma X3 All Terrain Wheelchair is
                        capable of.
                      </p>
                      <Link className="btn mt-4" to="/the-emma-x3/">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="hero-slider">
          <div className="slide5" style={slide5th}>
            <div className="container">
              {" "}
              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      <h1 className="heading-yellow">Meet The EMMA X3</h1>
                      <p style={{ maxWidth: "75%" }}>
                        The Emma X3 all terrain wheelchair is light,
                        comfortable, compact, it glides over sand, rocks, grass,
                        gravel and it even floats in water. Don’t believe us?
                      </p>
                      <p style={{ maxWidth: "65%" }}>
                        Discover what the Emma X3 All Terrain Wheelchair is
                        capable of.
                      </p>
                      <Link className="btn mt-4" to="/the-emma-x3/">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide> */}
    </Swiper>
  )
}

export default SwiperTest
