import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import HeroSwiper from "@components/swiper/hero-swiper"
import { StaticImage } from "gatsby-plugin-image"

export default function HeroSection() {
  return (
    <section>
      <div className="hero-section">
        <HeroSwiper />
        <div className="brandstamp text-center">
          <StaticImage src="../../images/logo.svg" width={200} />
          <h1 className="mb-0 mt-4">BONA FIDES LABORATORY, INC.</h1>
          <p className="mb-5">Colorado Owned & Operated</p>
          <Link to="/contact" className="btn">
            {" "}
            Begin Working With Us
          </Link>
        </div>
      </div>
    </section>
  )
}
