import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
// import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap"
import WebriqForm from "@webriq/gatsby-webriq-form"
import { Envelope } from "react-bootstrap-icons"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

export default function AboutSection() {
  // Modal open state
  const [modal, setModal] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  // Toggle for Modal
  const toggle = () => setModal(!modal)

  return (
    <section className="about-section">
      <div id="about" />

      <Container>
        <Row>
          {" "}
          <Col lg="3">
            <h2 className="sub-heading my-0">About</h2>
            <h2 className="mt-0">BONA FiDES LABORATORY, INC.</h2>
          </Col>
          <Col lg="9">
            <StaticImage
              height={183}
              width={1001}
              src="../../images/about-img2.jpeg"
            />
          </Col>
        </Row>
        <Row>
          {" "}
          <Col lg="3" className="my-3">
            <p>
            Bona Fides Laboratory Inc. is a Colorado owned and operated, state of the art cannabis analytical laboratory. We can meet all of your marijuana testing needs and have a proven record of accomplishment with reliable laboratory testing.  Our laboratory owners have been working in the production laboratory business for 20+ years.

            </p>
          </Col>
          <Col lg="3" className="my-3">
            <p>
            Our highly qualified staff includes two PhDs in Analytical Chemistry with 35+ years of analytical chemistry experience specializing in liquid chromatography combined with a group of analytical chemists and microbiologists.  We are accredited to ISO/IEC 17025:2017 and certified by the state of Colorado (CDPHE).

            </p>
          </Col>
          <Col lg="3" className="my-3">
            <p>
            An accredited laboratory provides Defensible (data can be defended in a court of law) Traceable (results can be traced to proper methods and standards) Repeatable (methods can be repeated to obtain the same result) data.
            </p>
          </Col>
          <Col lg="3" className="my-3">
            <p>
            What would a product recall cost your business? Ensure that you have the highest level of confidence in our analytical testing results. Make sure that your laboratory is ISO/IEC 17025:2017 accredited.

            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="about-sub-section text-center">
        <h2 className="mt-0 mb-4 text-white">
          Defensible, Repeatable, and Traceable data 100% of the time.
        </h2>

        <Button variant="btn" onClick={handleShow}>
          Request a Courier
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Request a Courier</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <WebriqForm
              method="POST"
              data-form-id="cd83453b-dcf8-4526-ac79-d9c6b4a5b305"
              name="Sample Submission Form"
              className="form-contacts p-5 bg-gray-50"
              data-thankyou-url="/thank-you"
            >
              <div className="form-row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      className="form-control"
                      name="Name"
                      type="text"
                      id="fullName"
                      required=""
                      placeholder="Full Name"
                    />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      className="form-control"
                      name="Email Address"
                      type="email"
                      id="homeEmail"
                      required=""
                      placeholder="Email Address"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control h-auto"
                  name="Message"
                  id="message"
                  required=""
                  rows="4"
                  cols="50"
                  placeholder="Message"
                />
              </div>
              <div className="form-group">
                <div className="webriq-recaptcha" />
              </div>
              <div className="form-group mb-0">
                <button className="btn btn-alt w-100" type="submit">
                  <Envelope className="mr-2" style={{ marginBottom: "2px" }} />
                  Send Message
                </button>
              </div>
            </WebriqForm>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </Container>
    </section>
  )
}
