import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import WebriqForm from "@webriq/gatsby-webriq-form"
import { Envelope } from "react-bootstrap-icons"

export default function ContactSection() {
  return (
    <section className="contact-section">
      <div id="contact" />
      <Container>
        <Row>
          <Col lg="6" className="mx-auto">
            {" "}
            <div className="contact-form text-center">
              <h2 className="my-0">SAMPLE SUBMISSION</h2>
              <p className="mb-5">
                To request a price quote or
                <br />
                additional Information
              </p>
              <WebriqForm
                method="POST"
                data-form-id="a23f96b0-6dec-4596-a6c2-918479365f7d"
                name="Sample Submission Form"
                className="form-newsletter"
                data-thankyou-url="/thank-you"
              >
                <div className="form-row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="First Name"
                        type="text"
                        id="firstName"
                        required=""
                        placeholder="First Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="Last Name"
                        type="text"
                        id="lastName"
                        required=""
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="Email Address"
                        type="email"
                        id="homeEmail"
                        required=""
                        placeholder="Email Address"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="Phone Number"
                        id="phone"
                        type="tel"
                        placeholder="Phone (optional)"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <textarea
                    className="form-control h-auto"
                    name="Message"
                    id="message"
                    required=""
                    rows="4"
                    cols="50"
                    placeholder="Message"
                  />
                </div>
                <div className="form-group">
                  <div className="webriq-recaptcha" />
                </div>
                <div className="form-group mb-0">
                  <button className="btn btn-alt w-100" type="submit">
                    <Envelope
                      className="mr-2"
                      style={{ marginBottom: "2px" }}
                    />
                    Send Message
                  </button>
                </div>
              </WebriqForm>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
